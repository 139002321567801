<template>
  <ContactWidgetCard :subheader="true" :headerTitle="contactTitle">
    <div class="dialer">
      <div class="dialer-info" :style="{ color: styleSet.color }">
        <div v-if="department">
          {{ calling }} {{ department.mainSettings.title }}...
        </div>
      </div>

      <Dialpad />

      <div class="dialer-button">
        <button @click="changeScreen('openContactList')" :style="buttonStyles">
          <Icon
            name="widget-telephone"
            style="width: 40px; heigth: 20px"
            :color="buttonStyles.color"
          />
        </button>
      </div>
    </div>
  </ContactWidgetCard>
</template>

<script setup>
import { useStore } from 'vuex'
import { inject, computed } from 'vue'

import useKeypadDialer from '@/modules/widget/composables/widget/useKeypadDialer'
import useWidgetConfig from '@/modules/widget/composables/useWidgetConfig'

import Dialpad from './Dialpad'
import ContactWidgetCard from './ContactWidgetCard'

const store = useStore()
const translatedValue = inject('translatedValue')
const department = computed(() => store.getters['widget/department'])
const contactTitle = computed(() =>
  translatedValue('contactList', 'Contact List')
)
const calling = computed(() => translatedValue('calling', 'Calling'))

const changeScreen = inject('changeScreen')
const dialogStatus = inject('dialogStatus')

const { number, deleteNumber } = useKeypadDialer()
const { activeContact, activeDepartment } = useWidgetConfig()

const appearance = computed(() => store.getters['widget/appearance'])

const styleSet = computed(() => {
  return {
    color: appearance.value?.bodyColor || '#9b9bc5' + '!important'
  }
})

const buttonStyles = computed(() => {
  return {
    background: appearance.value?.callHangBg || '#F52323' + '!important',
    color: appearance.value?.callHangColor || '#fff'
  }
})
</script>

<style lang="scss" scoped>
.dialer {
  padding: 2rem 1rem !important;
  overflow: visible !important;
  width: 100%;
  box-sizing: border-box;

  &-info {
    color: #9b9bc5;
    font-size: 30px;
    text-align: center;
    padding: 0 0 1rem 0 !important;

    .dailer-progress-info {
      display: flex;
      justify-content: center;
    }

    .microphone,
    .hold-buttons {
      i {
        cursor: pointer;
        margin: 0.5rem 1rem 0 0;
      }
    }
  }

  &-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem 0;

    button {
      background: #f52323;
      color: #fff;
      padding: 1.4rem;
      border: 0;
      outline: 0;
      border-radius: 50%;
      cursor: pointer;
      box-shadow: 0 2px 1px rgba(0, 0, 0, 0.2);
      transition: all 0.2s;

      i {
        font-size: 20px;
      }

      &:hover {
        opacity: 0.9;
      }

      &:focus,
      &:active {
        transform: translateY(5%);
      }
    }
  }
}
</style>
