<template>

  <ContactWidgetCard :headerTitle="contactTitle" :subheader="false">

    <template v-if="loading.value && loading.id == 'widget'">
      <ListLoader :count="3" :option="false" style="width: 100%;" />
    </template>
    
    <template v-else>
      
      <div class="widget-accordion">
        
        <div class="widget-accordion-tab" v-for="contact in widget.contacts" :key="contact.id">
          
          <h4 
            class="widget-accordion-title flex justify-between items-center" 
            :style="styleSet"
            @click="setResource(contact)">
            <span>{{ contact.name }}</span>
            <font-awesome-icon :icon="isActiveConfig(contact.slug) ? 'angle-up' : 'angle-down'" />
          </h4>

          <DepartmentAccordion 
            v-if="isActiveConfig(contact.slug)" 
            :departments="contact.departments"
            :contactId="contact.id"
          />
          
        </div>

      </div>

    </template>


  </ContactWidgetCard>

</template>

<script setup>

import DepartmentAccordion from './DepartmentAccordion'
import ContactWidgetCard from './ContactWidgetCard'
import ListLoader from '../home/ListLoader'
import useActiveToggler from '@/composables/useActiveToggler'
import useWidgetConfig from '../../composables/useWidgetConfig'

import { useStore } from 'vuex'
import { isEmpty } from 'lodash'
import { computed, inject } from 'vue'

const store = useStore()

const { setActiveResource } = useWidgetConfig()
const { toggleConfig, isActiveConfig } = useActiveToggler()

const widget = computed(() => store.getters['widget/activeWidget'])
const department = computed(() => store.getters['widget/department'])
const loading = computed(() => store.getters['widget/loading'])
const multiLang = computed(() => store.getters['widget/multiLang'])
const appearance = computed(() => store.getters['widget/appearance'])

const contactTitle = computed(() => translatedValue('contactList', 'Contact List'))

const styleSet = computed(() => {
  return {
    background: appearance.value?.contactListBackground || '#E0E0E0',
    color: appearance.value?.contactListColor || '#000'
  }
})

const translatedValue = inject('translatedValue')

const setResource = async (contact) => {
  await setActiveResource({ contactId: contact.id })
  toggleConfig(contact.slug)
}

</script>

<style lang="scss">

.widget-accordion-tab {
    
    .widget-accordion-title {
    
      background: #E0E0E0;
      padding: .6rem;
      position: relative;
      cursor: pointer;
      margin: .4rem 0 0 0;
      i {
        position: absolute;
          right: 1.2rem;
          transform: trnaslate(-50%, -50%);
      }
    } 
    
    &:last-of-type {
      margin-bottom: 1rem;
    }

    .widget-accordion-content {

      background: #fff;
      display: grid;
      grid-template-columns: 85% 15%;

      .body {
        background: #EBEBF3;
        box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.25);
        min-height: 303px;
        max-height: 303px;
        overflow: auto;
        margin-top: 0;

        &::-webkit-scrollbar {
          width: 5px;
        }
        
        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgb(224, 224, 224);
        }
        
        &::-webkit-scrollbar-thumb {
          background-color: darkgrey;
          outline: 1px solid rgb(224, 224, 224);
        }

        ul {
        
          margin: 0;
          padding: 0;
        
          li {
              background: #BBBBD7;
              border-radius: 4px;
              padding: 1.2rem 1rem;
              margin: 1rem 1.5rem;  
              list-style: none;
              position: relative;
              transition: all 0.4s;
              cursor: pointer;

              span.icon {
                background: #fff;
                color: #BBBBD7;
                width: 2.45rem;
                height: 2.45rem;
                border-radius: 50%;
                position: absolute;
                left: -9px;
                top: 47.2%;
                transform: translateY(-50%);
                box-shadow: 0px 3px 0px rgba(0, 0, 0, 0.25);
                display: flex;
                justify-content: center;
                align-items: center;
                transition: all 0.4s;
              }

              span.title {
                position: absolute;
                left: 2.5rem;
                top: 50%;
                transform: translateY(-50%);
                font-family: inherit;
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 15px;
                display: flex;
                align-items: center;
                color: #fff;
              }

              

          }

          li.active {
              
              background: #fff !important;
              border-radius: 24px 0px 0px 24px;
              padding: 1.6rem 1rem; 
              margin-right: 0;
              margin-left: .4rem;

              span.icon {
                background: #BBBBD7 !important;
                color: #fff !important; 
                box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);
                left: .5rem;
                top: 48%;
              }

              span.title {
                color: #BBBBD7 !important; 
                left: 3.5rem;
              }

          }

        }

      }

      .sidebar {
          
        ul {
          
          margin: 0;
          padding: 0;

          display: flex;
          flex-flow: column;
          align-items: center;
          
          li {
            
            list-style: none;
            padding: .2rem;
            width: 2.8rem;
            height: 2.8rem;
            border-radius: 50%;
            background: #EBEBF3;
            box-shadow: -6px -2px 16px rgba(255, 255, 255, 0.7);
            display: flex;
            justify-content: center;
            align-items: center;
            margin: .8rem .5rem 0 .5rem;
            cursor: pointer;
            transition: all .2s;

          // @media screen and (max-height: 1080px) {
          //   height: 2.5rem;
          //   width: 2.5rem;
          // } 

            &:hover {
              opacity: .8;
            }

          }

        }

      }

    }

}  

</style>
