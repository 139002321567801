<template>
    <div class="widget-button" @click.prevent="toggleModal">
        <i class="fas fa-phone"></i>
    </div>
</template>

<script setup>

    const emit = defineEmits(['open'])
    const toggleModal = () => emit('open')

</script>

<style scoped lang="scss">

    .widget-button {
        background: #1AB394;
        width: 60px;
        height: 60px;
        position: fixed;
        bottom: 40px;
        right: 20px;
        color: #fff;
        border-radius: 50%;
        box-shadow: 5px 5px 20px rgba(0, 0, 0, .4) !important;
        cursor: pointer;
        transition: all .3s;
        line-height: 60px;
        text-align: center;

        &:hover {
            transform: scale(1.09);
        }

        &::after {
            content: "";
            position: absolute;
            width: 40px;
            height: 50px;
            bottom: -7px;
            right: -10px;
            background: inherit;
            clip-path: polygon(53% 56%, 79% 100%, 29% 75%);
        }

    }

</style>