
import { ref, provide } from "vue"

export default function useKeypadDialer() {

    const number = ref('')

    const dialNumber = (dialedNumber)=> {
        number.value += dialedNumber
    }

    const deleteNumber = ()=> {
        number.value = number.value.slice(0, number.value.length - 1)
    }

    provide('dialNumber', dialNumber)

    return {
        number,
        dialNumber,
        deleteNumber
    }

}