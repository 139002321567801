<template>
  <component :is="currentScreen"></component>
</template>

<script>
import { isEmpty } from "lodash";
import { useStore } from "vuex";
import { ref, provide, computed, watch } from "vue";
import EmailScreen from "./EmailScreen";
import ChatScreen from "./Chat/Screen";
import WidgetBox from "./WidgetBox";
import OpenContactList from "./OpenContactList";
import PhoneDialerScreen from "./PhoneDialerScreen";
import VideoPhoneDialerScreen from "./VideoPhoneDialerScreen";
import PhoneDialerScreenWithNumber from "./PhoneDialerScreenWithNumber";
import ContactWidgetCard from "./ContactWidgetCard";
import useWidgetConfig from "../../composables/useWidgetConfig";
import useActiveToggler from "@/composables/useActiveToggler";

export default {
  components: {
    WidgetBox,   
    ChatScreen,
    EmailScreen,
    OpenContactList,
    PhoneDialerScreen,
    ContactWidgetCard,
    VideoPhoneDialerScreen,
    PhoneDialerScreenWithNumber,
  },

  setup() {
    const currentScreen = ref("OpenContactList");
    const dialogStatus = ref(false);
    const dialogId = ref("");
    const modal = ref(false);
    const store = useStore();

    const { setActiveResource } = useWidgetConfig();
    const { toggleConfig, isActiveConfig } = useActiveToggler();
    const widget = computed(() => store.getters["widget/activeWidget"]);
    const multiLang = computed(() => store.getters["widget/multiLang"]);
    const appearanceBackground = computed(
      () => store.getters["widget/appearanceBackground"]
    );
    const appearanceColor = computed(
      () => store.getters["widget/appearanceColor"]
    );

    const changeScreen = (screen) => {
      if (screen == null) return;
      currentScreen.value = screen;
      toggleDialog("");
    };

    const toggleDialog = (id) => {
      dialogId.value = id;
      dialogStatus.value = !dialogStatus.value;
    };

    const toggleModal = (val = true) => (modal.value = val);

    const translatedValue = (key, defaultValue) => {
      if (isEmpty(multiLang.value)) return defaultValue;
      const userSavedLang =
        multiLang.value.userEditedLangs[multiLang.value.lang];
      key = userSavedLang ? userSavedLang[key] : null;
      return !isEmpty(key) ? key : defaultValue;
    };

    provide("changeScreen", changeScreen);
    provide("toggleDialog", toggleDialog);
    provide("dialogStatus", dialogStatus);
    provide("dialogId", dialogId);
    provide("toggleModal", toggleModal);
    provide("translatedValue", translatedValue);

    watch([appearanceBackground, appearanceColor], ([value, colorValue]) => {
      const departmentBgs = [
        "departmentBackground",
        "departmentListBackground",
        "departmentListIcon",
        "departmentListActiveIcon",
      ];
      const departmentColors = [
        "departmentListColor",
        "departmentListIconColor",
        "departmentListActiveIconColor",
      ];

      const iconBgs = ["sidebarBackground", "sidebarIconBackground"];
      const iconColors = ["sidebarIconColor"];

      const dialerBgs = ["dialerButtonBg", "callHangBg"];
      const dialerColors = ["dialerButtonColor", "callHangColor"];

      if (
        departmentBgs.includes(value) ||
        departmentColors.includes(colorValue)
      ) {
        if (widget.value.contacts && widget.value.contacts.length) {
          const contact = widget.value.contacts[0];
          setActiveResource({ contactId: contact.id });
          if (!isActiveConfig(contact.slug)) toggleConfig(contact.slug);
          changeScreen("OpenContactList");
        }
      }

      if (iconBgs.includes(value) || iconColors.includes(colorValue)) {
        if (widget.value.contacts && widget.value.contacts.length) {
          const contact = widget.value.contacts[0];
          const department = contact?.departments?.length
            ? contact.departments[0]
            : {};
          setActiveResource({
            contactId: contact.id,
            departmentId: department.id,
          });
          if (!isActiveConfig(contact.slug)) toggleConfig(contact.slug);
          changeScreen("OpenContactList");
        }
      }

      if (dialerBgs.includes(value) || dialerColors.includes(colorValue)) {
        if (widget.value.contacts && widget.value.contacts.length) {
          const contact = widget.value.contacts[0];
          const department = contact?.departments?.length
            ? contact.departments[0]
            : {};
          setActiveResource({
            contactId: contact.id,
            departmentId: department.id,
          });
        }
        changeScreen("PhoneDialerScreen");
      }

      if (value == "submitButtonBg" || colorValue == "submitButtonColor") {
        if (widget.value.contacts && widget.value.contacts.length) {
          const contact = widget.value.contacts[0];
          const department = contact?.departments?.length
            ? contact.departments[0]
            : {};
          setActiveResource({
            contactId: contact.id,
            departmentId: department.id,
          });
        }
        changeScreen("PhoneDialerScreenWithNumber");
      }
    });

    return {
      currentScreen,
    };
  },
};
</script>
