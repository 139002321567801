<template>
  
    <div class="widget-accordion-content">

        <div class="body" :style="styleSet">
            
            <ul>
                <li v-for="department in departments" 
                    :key="department.id"
                    :class="{'active': isActive(department.id)}" 
                    @click.prevent="setActiveResource({ contactId: contactId, departmentId: department.id })"
                    :style="departmentStyle"
                >
                    <span 
                        class="icon" 
                        :title="$t('label.widget.multilanguage.modal.home')"
                        :style="isActive(department.id) 
                        ? departmentIconsActiveStyles 
                        : departmentIconsStyles"
                    >
                        <Icon 
                            name="widget-home"
                            :color="isActive(department.id) 
                            ? departmentIconsActiveStyles.color 
                            : departmentIconsStyles.color"  
                        />
                    </span>
                    <span class="title" :style="{ color: departmentStyle.color }">
                        {{ department.mainSettings.title }}
                    </span>
                </li>
            </ul>

        </div>

        <div class="sidebar" :style="sidebarStyle">

            <ul v-if="permissions.length">
                <template 
                    v-for="(permission, index) in permissions" 
                    :key="index">
                    <li 
                        @click.prevent="changeScreen(getWidgetScreen(permission))"
                        v-if="permissionValue(permission)"
                        :title="translate(getTitleOfIcon(permission), getTitleOfIcon(permission))"
                        :style="sidebarIconStyle"
                    >
                        <Icon 
                            v-if="getPermissionIcon(permission)"
                            :name="getPermissionIcon(permission)"
                            :color="sidebarIconStyle.color"
                        />
                    </li>
                </template>
            </ul>
            
        </div>

    </div>

</template>

<script setup>

import { inject, computed } from 'vue'
import { useStore } from 'vuex'
import useSettings from '../../composables/widget/useSettings'
import useWidgetConfig from '../../composables/useWidgetConfig'


defineProps({
    departments: {
        required: true,
        type: Array
    },
    contactId: {
        required: true,
        type: [String, Number]
    }
})

const store = useStore()

const department = computed(() => store.getters['widget/department'])
    
const changeScreen = inject('changeScreen')
const translate = inject('translatedValue')
const { setActiveResource } = useWidgetConfig()
const { getWidgetScreen, getPermissionIcon, getTitleOfIcon } = useSettings()

const isActive = (id) => department.value && id == department.value.id

const contactOptions = computed(() => department.value?.mainSettings?.contactOptions)

const permissions = computed(() => {
    return department.value?.mainSettings?.contactOptions != undefined 
    ? Object.keys(department.value.mainSettings.contactOptions) 
    : []
})

const permissionValue = (key) => {
    return contactOptions.value != null && contactOptions.value != undefined 
    ? contactOptions.value[key] 
    : null
}

const appearance = computed(() => store.getters['widget/appearance'])

const styleSet = computed(() => {
  return {
    background: appearance.value?.departmentBackground || '#EBEBF3',
  }
})

const departmentStyle = computed(() => {
    return {
        background: appearance.value?.departmentListBackground || '#bbbbd7',
        color: appearance.value?.departmentListColor || '#fff',
    }
})

const sidebarStyle = computed(() => {
    return {
        background: appearance.value?.sidebarBackground || '#fff'
    }
})

const sidebarIconStyle = computed(() => {
    return {
        background: appearance.value?.sidebarIconBackground || '#EBEBF3',   
        color: appearance.value?.sidebarIconColor || '#9B9BC5'   
    }
})

const departmentIconsStyles = computed(() => {
    return {
        background: appearance.value?.departmentListIcon,
        color: appearance.value?.departmentListIconColor
    }
})

const departmentIconsActiveStyles = computed(() => {
    return {
        background: appearance.value?.departmentListActiveIcon + '!important',
        color: appearance.value?.departmentListActiveIconColor
    }
})


</script>
