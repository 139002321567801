export default function useSettings() {
  const getWidgetScreen = (key) => {
    const screens = {
      phoneCalls: "PhoneDialerScreen",
      requestCallback: "PhoneDialerScreenWithNumber",
      chat: "ChatScreen",
      email: "EmailScreen",
      videoCalls: "VideoPhoneDialerScreen",
    };
    return screens[key];
  };

  const getPermissionIcon = (key) => {
    const screens = {
      phoneCalls: "widget-phone",
      requestCallback: "widget-phone-alt",
      chat: "widget-comment",
      email: "widget-email",
      videoCalls: "widget-video",
    };
    return screens[key];
  };

  const getTitleOfIcon = (key) => {
    const screens = {
      phoneCalls: "webcall",
      requestCallback: "callback",
      chat: "message",
      email: "email",
      videoCalls: "videocall",
    };
    return screens[key];
  };

  return {
    getTitleOfIcon,
    getWidgetScreen,
    getPermissionIcon,
  };
}
