<template>
  <div class="relative full-screen-width">
    <template v-if="!loading">
      
      <div
        v-if="!widgetStatus"
        class="widget-button"
        :style="position"
        @click.prevent="toggleWidget(true)"
        :class="animation"
      >
        <div
          class="animation--inner"
          :style="{ borderColor: color, boxShadow: `0 0 8px ${color}` }"
        >
        </div>

        <Icon name="widget-button" id="widgetButton" :color="color" />
        
        <label for="widgetButton" class="font-inter" :style="{ color: color }">
          {{ translate("widgetButtonLabel", "") }}
        </label>

      </div>

      <Widget v-else />

    </template>
  </div>
</template>

<script setup>
import { isEmpty } from "lodash";
import { computed, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import Widget from "../widget/index";
import useWidgetConfig from "@/modules/widget/composables/useWidgetConfig";
import usePhone from "@/modules/widget/composables/usePhone";

const route = useRoute();
const store = useStore();

const {
  getSubscriptionPlans,
} = usePhone();

const { toggleWidget } = useWidgetConfig();

const sidebar = computed(() => store.getters["widget/sidebar"]);
const multiLang = computed(() => store.getters["widget/multiLang"]);
const widgetStatus = computed(() => store.getters["widget/widgetStatus"]);

const loading = computed(() => {
  const { value, id } = store.getters["widget/loading"];
  return id == "widget" && value;
});

const position = computed(() => {
  const value = sidebar.value ? sidebar.value?.position : "right";
  return value == "left" ? value + ":34rem" : value + ":3rem";
});

const color = computed(() =>
  sidebar.value ? sidebar.value?.colorScheme : "#1AB394"
);

const animation = computed(() => {
  return sidebar.value ? sidebar.value?.animation : "";
});

const translate = (key, defaultValue) => {
  if (isEmpty(multiLang.value)) return defaultValue;
  const userSavedLang = multiLang.value.userEditedLangs[multiLang.value.lang];
  key = userSavedLang ? userSavedLang[key] : null;
  return !isEmpty(key) ? key : defaultValue;
};

onMounted(() => getSubscriptionPlans());
</script>

<style lang="scss">

.widget-button {
  position: fixed;
  bottom: 4%;
  cursor: pointer;
  z-index: 100;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.ripple {
  transition: all 0.3s ease-in-out 0s;
  .animation--inner {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    border-color: #1ab394;
    border-style: solid;
    border-width: 3px;
    position: absolute;
    z-index: -1;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: ring 2.5s infinite;
    box-shadow: 0 0 8px #1ab394;
  }

  &:hover,
  &:focus {
    color: #313133;
    transform: translateY(-6px);
  }
  
}

.ripple:hover::after,
.ripple:focus::after {
  animation: none;
  display: none;
}

.bounce {
  animation: bounce 0.8s infinite;
}

.shake {
  animation: shake 2s infinite cubic-bezier(0.36, 0.07, 0.19, 0.97);
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

.wiggle {
  animation-name: wiggle;
  animation-duration: 1s;
  transform-origin: 50% 50%;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes wiggle {
  0% {
    transform: translate(2px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(0px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(2px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(2px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

@keyframes ring {
  0% {
    width: 30px;
    height: 30px;
    opacity: 1;
  }
  100% {
    width: 200px;
    height: 200px;
    opacity: 0;
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: translateY(0);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}
</style>
