<template>

  <ContactWidgetCard :headerTitle="translate('leaveUsAMessage', 'Leave us a message')">

      <form action="" class="form" @submit.prevent="submitForm">

          <h4 class="form-title" :style="styleSet">{{ translate('email', 'Email') }}</h4>
          <div class="form-group" :style="styleSet">
            <input
              name="fullname" 
              type="text" 
              class="form-input" 
              :placeholder="translate('fullname', 'Full Name')" 
              v-model="form.fullname"
            />
          </div>

          <div class="form-group" :style="styleSet">
            <input 
              name="email" 
              type="email" 
              class="form-input" 
              :placeholder="translate('email', 'Email')" 
              v-model="form.email"
            />
          </div>

          <div class="form-group" :style="styleSet">
            <input 
              name="subject" 
              type="text" 
              class="form-input" 
              :placeholder="translate('subject', 'Subject')" 
              v-model="form.subject"
            />
          </div>

          <div class="form-group" :style="styleSet">
            <textarea 
                name="message" 
                class="form-input" 
                :placeholder="translate('message', 'Message')" 
                rows="9" 
                v-model="form.message"
              ></textarea>
          </div>

          <button 
            type="submit" 
            class="form-submit flex justify-center items-center gap-x-2"
            :style="submitStyles"
          >
            {{ translate('send', 'Send') }}
            <Icon name="widget-paper-send" style="float: right" :color="submitStyles.color" />
          </button>

      </form>

  </ContactWidgetCard>

</template>

<script setup>

import { useStore } from 'vuex'
import ContactWidgetCard from './ContactWidgetCard'
import { reactive, inject, computed } from 'vue'

const form = reactive({
  fullname: "",
  email: "",
  subject: "",
  message: ""
})

const store = useStore()
const translate = inject('translatedValue')

const submitForm = () => {}

const appearance = computed(() => store.getters['widget/appearance'])

const submitStyles = computed(() => {
  return {
    background: appearance.value?.submitButtonBg + ' !important',
    borderColor: appearance.value?.submitButtonBg + ' !important',
    color: appearance.value?.submitButtonColor || '#fff',
  }
})

const styleSet = computed(() => {
  return {
    color: appearance.value?.bodyColor || '#9b9bc5' + '!important'
  }
})


</script>

<style lang="scss">

  .form {

      margin: 0 .6rem;

      .has-error {
        border: 1px solid #dc3545 !important;
      }

      .error-text {
        color: #dc3545;
        font-family: inherit;
        font-size: 12px;
      }


      &-title {
        color: #9B9BC5;
        font-weight: bold;
        font-size: 36px;
        margin: .5rem;
        text-align: center;
      }

      &-group {

        &:not(:first-child) {
          margin: .5rem 0;
        }

        .form-input {

          padding: .6rem 1rem;
          border: 1px solid #9B9BC5;
          border-radius: 5px;
          width: 100%;
          font-size: 14px;
          box-sizing: border-box;


          &::placeholder {
            color: #9B9BC5;
            font-size: 14px;
          }


        }

        textarea.form-input {
          resize: none;
        }

      }

      &-submit {

        background: #9B9BC5 !important;
        color: #fff;
        text-transform: uppercase;
        width: 100%;
        padding: .6rem 1rem;
        text-align: center;
        outline: 0;
        border: 1px solid #9B9BC5;
        border-radius: 5px;
        font-style: bold;
        font-family: inherit;
        font-size: 16px;
        box-sizing: border-box !important;
        letter-spacing: .1rem;

        &:hover {
          opacity: .8;
        }

        i {
          float: right;
          padding-right: .1rem;
        }

      }

    }

</style>
