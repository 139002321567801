<template>

  <ContactWidgetCard 
  :headerTitle="chatText" 
  :subheader="true">

      <ChatView />  
  
  </ContactWidgetCard>


</template>

<script setup>

import { inject, computed } from 'vue'

import ChatView from './View'
import ContactWidgetCard from '../ContactWidgetCard'

const translatedValue = inject('translatedValue')
const chatText = computed(() => translatedValue('chatWithAPerson', 'Chat with a Person'))

</script>

<style lang="scss">

  .chat-container {
            
      position: relative;

      .chat-footer {
          
          position: absolute;
          top: 100%;
          left: 0;
          background: #EBEBF3;
          width: 100%;
          padding: 1rem;
          //transform: translateY(-50%);
          box-sizing: border-box;

        .person-writing-message {

          font-style: normal;
          font-weight: bold;
          font-size: 10px;
          line-height: 12px;
          color: #000;
          margin-bottom: 3px;
        }


        .chat-form {
          
          display: grid;
          grid-template-columns: 85% 15%;
          
          .form-input {
            width: 100%;  
            padding: .5rem;
            margin-right: 10rem;
            box-sizing: border-box;
            z-index: 2;
            border: 0;
            outline: 0;
          }

          button {

            background: #9B9BC5 !important;
            color: #fff;
            width: 100%;
            justify-self: center;
            border: 0;
            z-index: 1000;
            opacity: 1;
            margin-left: 10px;
            position: relative;
            cursor: pointer;
            
          }

        }

      }

  }

  .chat {

      padding: .5rem 1rem;
      position: relative;
      height: 446px !important;
      overflow: auto; 

      /* width */
      &::-webkit-scrollbar {
        width: 4px !important;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1; 
      }
      
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: rgb(204, 203, 203); 
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #ccc; 
      }

      &-box {

        display: grid;
        grid-template-columns: 88% 12%;
        margin: .7rem 0;

        .chat-time {

          font-size: 12px;
          color: #9B9BC5;
          justify-self: center;
          font-weight: bold;
        
        }

      }

      .box-you {
        grid-template-columns: 12% 88%;
      }
      
      .you, .other {

        padding: .7rem 1rem;
        width: 100%;
        background: #9B9BC5;                            
        box-sizing: border-box;

        .chat-text {
          
          color: #000000;
          font-weight: bold;
          font-family: inherit;
          font-size: 14px;

        }

      }

      .you {
        
        border-radius: 0px 8px 8px 8px;
        .chat-text {
          color: #fff;
        }

      }

      .other {
        background: #EBEBF3;
        border-radius: 8px 0px 8px 8px;
      }

  }

</style>
