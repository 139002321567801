<template>
    
    <div class="chat-box box-other">
            
        
        <div class="other">
            <span class="chat-text">{{ title }}</span> 
        </div>
        
        <div class="chat-time">{{ time }}</div>

    
    </div>

</template>

<script>
export default {
    props: {
        title: {
            required: true,
            type: String
        },
        time: {
            required: true,
            type: String
        }
    }
}
</script>