<template>
    <div class="dialer-pad">
        <span 
          v-for="dialpad in dialpads"
          :key="dialpad.no"
          class="dialer-pad-button button" 
          @click.prevent="addNumber(dialpad.no)"
          :style="styleSet"
        >
            {{ dialpad.no }}
            <span :class="{ 'invisible': dialpad.invisible }">{{ dialpad.label }}</span>
        </span>
       
    </div>

</template>

<script setup>

  import { inject, computed } from 'vue'
  import { useStore } from 'vuex'

  let addNumber = inject('dialNumber')
  const store = useStore()
  
  const dialpads = [{
    no: 1,
    label: '+',
    invisible: true
  },{
    no: 2,
    label: 'abc'
  },{
    no: 3,
    label: 'dfg'
  },{
    no: 4,
    label: 'ghi'
  },{
    no: 5,
    label: 'jkl'
  },{
    no: 6,
    label: 'mno'
  },{
    no: 7,
    label: 'pqrs'
  },{
    no: 8,
    label: 'tuv'
  },{
    no: 9,
    label: 'wxyz'
  },{
    no: '*',
    label: '',
    invisible: true
  },{
    no: 0,
    label: '+',
  },{
    no: '#',
    label: '+',
    invisible: true
  }]

  const appearance = computed(() => store.getters['widget/appearance'])

  const styleSet = computed(() => {
    return {
      background: appearance.value?.dialerButtonBg || '#fff',
      color: appearance.value?.dialerButtonColor || '#000'
    }
  })

</script>

<style lang="scss" scoped>

    .dialer-pad {
        
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        justify-content: center;
        align-items: center;
        padding: 1.5rem 0 !important;
        box-sizing: border-box;
        
        &-button {

          background: #fff;
          box-shadow: 1px 1.5px 1.3px rgba(0, 0, 0, 0.3);
          width: 100%;
          text-align: center;
          color: #000;
          box-sizing: border-box;
          font-family: inherit;
          font-style: normal;
          font-size: 30px;
          border-radius: 7px;
          height: 44px;
          width: 109px;
          margin: .2rem 4px;
          display: flex;
          justify-content: center;
          flex-flow: column;
          cursor: pointer;
          transition: all .2s;

          span {
            opacity: 0.4;
            font-size: 10px;
            text-transform: uppercase;
            margin-top: -12px;
          }

          
          &:hover {
            background: #E0E0E0;
          }

          &:focus,
          &:active {
              background: #E0E0E0;
              transform: translateY(5%);  
          }

        }

    }
</style>