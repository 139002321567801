<template>

  <div class="widget-card" :style="styleSet">

    <WidgetCardHeader :title="headerTitle" />
    <WidgetCardSubheader v-show="subheader" />

    <div class="widget-card-content">

      <slot></slot>

    </div>

    <WidgetCardFooter />

  </div>

</template>

<script setup>

import { computed } from 'vue'
import { useStore } from 'vuex'
import WidgetCardHeader from './Layout/WidgetCardHeader'
import WidgetCardSubheader from './Layout/WidgetCardSubheader'
import WidgetCardFooter from './Layout/WidgetCardFooter'

defineProps({
  subheader: {
    type: Boolean,
    default: true
  },
  headerTitle: {
    type: String,
    required: true
  }
})

const store = useStore()

const appearance = computed(() => store.getters['widget/appearance'])

const styleSet = computed(() => {

  const position = appearance.value?.widgetPosition || 'right'

  return {
    [position]: position == 'left' ? '34rem': '3rem',
    background: appearance.value?.bodyBackground || '#fff',
    color: appearance.value?.bodyColor || '#9b9bc5' + '!important'
  }

})


</script>

<style lang="scss">

  .widget-card {

    width: 300px;
    height: 480px;
    background: #fff;
    font-family: 'Roboto', sans-serif; 
    margin: 0;
    padding: 0; 
    position: fixed;
    right: 1rem !important;
    bottom: 2%;
    border: 1px solid #ccc;
    box-sizing: border-box;
    z-index: 5;
    text-align: left;
    font-size: 12px;

    @media only screen and (min-height: 700px) {
      width: 360px;
      height: 640px;
    }

      &-header {

        background: #333;
        color: #fff;
        padding: .8rem .5rem;
        text-transform: uppercase;
        position: relative;
        font-size: 12px;
        font-family: inherit;
        height: 43px;

        .icon {
          position: absolute;
          right: 2rem;
          top: 30%;
          transform: trnaslate(-50%, -50%);
          height: 100%;
        }

        .icon:last-of-type {
          right: 1rem;
        }

      }

      &-subheader {

        background: #EBEBEB;
        padding: .5rem 1rem;

        display: grid;
        grid-template-columns: 20% 80%;

        ul.icons {
          margin: 0;
          padding: 0;
          
          li {
            
            list-style: none;
            display: inline-block;
            cursor: pointer;

            &:not(:first-child) {
              margin-left: .5rem;
            }

            i, svg {
              color: #9B9BC5;
              background: #fff;
              width: 1.8rem;
              height: 1.8rem;
              border-radius: 50%;
              font-size: 16px;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 4px;

              &:hover {
                box-shadow: 2px 2px 10px rgba(0, 0, 0, .4) !important;
              }

            }

          }

        }

        ul.icons-right {
          justify-self: right;
        }

        ul.icons-left {
          
          li {

            i {
              background: #9B9BC5;
              color: #fff;
            }

          }

        }

      }

      &-content {
        box-sizing: border-box;
        height: calc(640px - 86px);
        overflow-y: auto;

        &::-webkit-scrollbar {
          width: 5px;
        }
        
        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgb(224, 224, 224);
        }
        
        &::-webkit-scrollbar-thumb {
          background-color: darkgrey;
          outline: 1px solid rgb(224, 224, 224);
        }

      }

      &-footer {

        background: #000;
        border: 1px solid #000;
        color: #fff;
        padding: .8rem .6rem;
        text-transform: uppercase;
        position: absolute;
        font-size: 10px;
        top: 100%;
        left: 0;
        width: 100%;
        height: 43px;
        transform: translateY(-43px);

        .right {
          text-transform: uppercase;
          position: absolute;
          right: 1rem;
          color: #9B9BC5;
        }

        .text-small {
          text-transform: lowercase;
        }

        .bold {
          font-weight: bold;
          margin-left: 2px;
        }

      }
      
  }

</style>
