<template>

    <div class="widget-card-header" :style="styleSet">

      {{ title }}
      
      <div class="icon" @click="toggleDialog('headerNotification')" style="cursor: pointer">
        <font-awesome-icon icon="info-circle" />
      </div>
      
      <div class="icon" style="cursor: pointer" @click.prevent="toggleWidget(false)">
        <font-awesome-icon icon="times" :title="$t('text.app.common.close')" />
      </div>

    </div>

    <BaseDialog
        :open="dialogStatus && dialogId == 'headerNotification'" 
        @close="toggleDialog" 
        id="headerNotification"
    >
    </BaseDialog>


</template>

<script setup>

import { useStore } from 'vuex'
import { inject, computed } from 'vue'
import useWidgetConfig from '@/modules/widget/composables/useWidgetConfig'    

defineProps({
  title: {
    required: true,
    type: String
  }
})

const store = useStore()
const toggleDialog = inject('toggleDialog')
const dialogStatus = inject('dialogStatus')
const dialogId     = inject('dialogId')

const { toggleWidget } = useWidgetConfig()

const appearance = computed(() => store.getters['widget/activeWidget'].appearance)

const styleSet = computed(() => {
  return {
    background: appearance.value?.headerBackground,
    color: appearance.value?.headerColor
  }
})

</script>

