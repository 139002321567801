<template>
  <ContactWidgetCard
    :headerTitle="translate('contactUs', 'Contact Us')"
    class="webphone-box"
  >
    <div class="dialer webphone-box-container">
      <div
        class="dialer-name"
        v-if="!callOpt"
        :style="{ color: styleSet.color }"
      >
        {{
          translate(
            'bestCallbackTime',
            'When is the best time to call you back'
          )
        }}?
        <span>
          <br />
          <br />
          <!-- {{
            translate(
              'bestCallbackTimePara',
              'Please indicate the time when it is convenient for you to receive a call'
            )
          }} -->
        </span>
        <span class="call-me" @click.prevent="callOpt = true">
          {{ translate('callMeNow', 'Call me now') }}
        </span>
      </div>

      <div class="dialer-name" v-else :style="{ color: styleSet.color }">
        {{ translate('callMeNow', 'Call me now') }}
        <span>
          {{
            translate(
              'callForFree',
              'Please enter your number and we will call you for free'
            )
          }}
        </span>
        <span class="call-me" @click.prevent="callOpt = false">
          {{ translate('callMeLater', 'Call me later') }}
        </span>
      </div>

      <div class="selects form" v-show="!callOpt">
        <div class="form-group">
          <select name="" id="" class="form-input">
            <option :value="day.toLowerCase()" v-for="day in days" :key="day">
              {{ day }}
            </option>
          </select>
        </div>

        <div class="form-group">
          <select name="" id="" class="form-input">
            <option :value="time" v-for="time in timeslots" :key="time">
              {{ time }}
            </option>
          </select>
        </div>
      </div>

      <div class="intl-phone-input form-group">
        <div class="dropdown" v-show="show">
          <li
            v-for="country in countries"
            :key="country"
            @click="selectCountry(country)"
          >
            <img
              src="@/assets/uk-flag.svg"
              alt="Uk Flag"
              style="width: 25px; height: 25px"
            />
            {{ country }}
          </li>
        </div>

        <div class="phone-country" @click.prevent="show = !show">
          <img
            src="@/assets/uk-flag.svg"
            alt="Uk Flag"
            style="width: 25px; height: 25px"
          />
          <span>{{ intlCountry }}</span>
          <i class="fas fa-angle-down"></i>
        </div>

        <input type="text" placeholder="+44" class="form-input" />
      </div>

      <div
        class="dialer-button dial-hangup-container"
        style="
          position: absolute;
          top: 100%;
          width: 100%;
          box-sizing: border-box;
          left: 0;
          padding: 1rem;
        "
      >
        <button
          class="form-submit dial"
          style="cursor: pointer"
          :style="submitStyles"
        >
          {{ translate('sendNumber', 'send number') }}
          <Icon
            name="widget-paper-send"
            style="float: right"
            :color="submitStyles.color"
          />
        </button>
        <a class="hangup hide"></a>
      </div>
    </div>
  </ContactWidgetCard>
</template>

<script setup>
import { useStore } from 'vuex'
import { ref, inject, computed } from 'vue'
import Dialpad from './Dialpad'
import ContactWidgetCard from './ContactWidgetCard'
import useKeypadDialer from '@/modules/widget/composables/widget/useKeypadDialer'

const store = useStore()
const callOpt = ref('')
const intlCountry = ref('UK')
const show = ref(false)

const toggleDialog = inject('toggleDialog')
const dialogStatus = inject('dialogStatus')
const dialogId = inject('dialogId')
const translate = inject('translatedValue')

const { number, deleteNumber } = useKeypadDialer()

const selectCountry = country => {
  intlCountry.value = country
  show.value = false
}

const days = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
]
const timeslots = ['00:00', '00:15', '00:30', '01:00', '01:15', '01:30']
const countries = ['UK', 'BG', 'DE', 'US']

const appearance = computed(() => store.getters['widget/appearance'])

const styleSet = computed(() => {
  return {
    color: appearance.value?.bodyColor || '#9b9bc5' + '!important'
  }
})

const submitStyles = computed(() => {
  return {
    background: appearance.value?.submitButtonBg + ' !important',
    borderColor: appearance.value?.submitButtonBg + ' !important',
    color: appearance.value?.submitButtonColor || '#fff'
  }
})
</script>

<style lang="scss" scoped>
.dialer {
  padding: 1rem 1rem 0 1rem !important;
  overflow: visible !important;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  min-height: 400px;

  .intl-phone-input {
    position: relative;
    margin-left: 0.5rem;
    .dropdown {
      position: absolute;
      top: 100%;
      background: #fff;
      text-align: center;
      width: 25%;
      z-index: 5;
      li {
        list-style: none;
        padding: 0.4rem 0;
        display: flex;
        align-items: center;
        column-gap: 0.2rem;
        justify-content: center;
        cursor: pointer;
      }
    }
    .phone-country {
      position: absolute;
      background: #e0e0e0;
      height: 98%;
      width: 25%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border: 1px solid #e0e0e0;
      column-gap: 0.2rem;
    }
    input {
      background: 0 !important;
      padding-left: 5.3rem;
    }
  }

  .selects {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    column-gap: 0.6rem;
    margin-top: 0;
  }

  .form-group {
    width: 100%;
    .form-input {
      width: 100%;
      border: 2px solid #e0e0e0 !important;
      background: #e0e0e0;
      border-radius: 4px;
    }
  }

  &-name {
    color: #9b9bc5;
    font-size: 35px;
    line-height: 42px;
    text-align: center;
    padding: 0 0 1.4rem 0;
    font-weight: bold;
    font-family: Inter;
    font-style: normal;

    span {
      font-size: 14px;
      line-height: 17px;
      font-weight: normal;
      display: block;
      padding: 0 3rem;
      margin-top: 1rem;
    }

    .call-me {
      color: #ff5252;
      font-size: 14px;
      font-weight: 700;
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
    }
  }

  &-input {
    text-align: center;
    position: relative;
    padding: 0 0rem;
    // margin: 0 .8rem 0 0;

    input {
      color: #000;
      font-size: 14px;
      font-weight: bold;
      font-family: inherit;
      padding: 0.5rem;
      width: 100%;
      box-sizing: border-box;
      border: 1px solid #e0e0e0;
      outline: 0;
    }

    button {
      background: #e0e0e0;
      border: 0;
      outline: 0;
      padding: 0.55rem 1rem;
      font-size: 14px;
      position: absolute;
      top: 0;
      right: 0;
      border-radius: 5px 3px;
      color: #9b9bc5;
      cursor: pointer;
    }
  }
}
</style>
