<template>
    
    <div class="chat-box box-you">
            
        <div class="chat-time">{{ time }}</div>
        <div class="you">
            <span class="chat-text">{{ title }}</span> 
        </div>

    </div>

</template>

<script>
export default {
    props: {
        title: {
            required: true,
            type: String
        },
        time: {
            required: true,
            type: String
        }
    }
}
</script>