<template>
    
    <div class="chat-container">

        <div class="chat" v-if="messages.length">

            <template v-for="message in messages" :key="message.id">
                <BoxOther 
                    :title="message.body" 
                    :time="time(message.time)" 
                    v-if="message.originator == 'remote'" 
                />
                <BoxYou 
                    :title="message.body" 
                    :time="time(message.time)" 
                    v-else 
                />
            </template>
    
        </div>

        <div class="chat" v-else>
            <p>{{ noNewMessages }}</p>
        </div>

        <div class="chat-footer">
            <div class="chat-form">
                <input 
                    type="text" 
                    class="form-input" 
                    v-model="message" 
                    :placeholder="chatText"
                />
                <button style="background: #000;">
                    <font-awesome-icon icon="paper-plane" />
                </button>
            </div>
        </div>

    </div>
    

</template>

<script>
export default {
    name: 'chatView',
}
</script>

<script setup>

import BoxOther from './BoxOther'
import BoxYou from './BoxYou'
import moment from 'moment'
import { ref, reactive, computed, inject } from 'vue'

const messages = reactive([{
        id: 1,
        body: 'Hello',
        time: Date.now(),
        originator: 'remote'
    },{
        id: 2,
        body: 'Yes! Hello',
        time: Date.now(),
    }
])

const message = ref('')
const time = (date) => moment(date).format('HH:mm')

const translatedValue = inject('translatedValue')
const chatText = computed(() => translatedValue('chatWithAPerson', 'Chat with a Person'))
const noNewMessages = computed(() => translatedValue('noNewMessages', 'No new messages yet'))

</script>   