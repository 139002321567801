<template>

  <ContactWidgetCard :subheader="true" :headerTitle="translate('contactList', 'Contact List')">
      
      <div class="dialer">
          <div class="dialer-info" :style="{ color: styleSet.color }">

            <div>
              {{ translate('calling', 'Calling') }}...
            </div>

          </div>

          <div>
              <div class="lines"></div>
              <Dialpad />
          </div>


          <div class="dialer-button">
            <button @click="changeScreen('openContactList')">
              <Icon name="widget-telephone" style="width: 40px; heigth: 20px" />
            </button>
          </div>

      </div>

  </ContactWidgetCard>

</template>

<script setup>

import { useStore } from 'vuex'
import { inject, computed } from 'vue'

import useKeypadDialer from '@/modules/widget/composables/widget/useKeypadDialer'
import useWidgetConfig from '@/modules/widget/composables/useWidgetConfig'

import Dialpad from './Dialpad'
import ContactWidgetCard from './ContactWidgetCard'

const store = useStore()
const changeScreen = inject('changeScreen')
const dialogStatus = inject('dialogStatus')
const translate = inject('translatedValue')

const { number, deleteNumber } = useKeypadDialer()
const { activeContact, activeDepartment } = useWidgetConfig()

const appearance = computed(() => store.getters['widget/appearance'])

const styleSet = computed(() => {
  return {
    color: appearance.value?.bodyColor || '#9b9bc5' + '!important'
  }
})

</script>

<style lang="scss" scoped>

  .dialer {
      
      padding: 2rem 1rem !important;
      overflow: visible !important;
      width: 100%;
      box-sizing: border-box;

      &-info {

        color: #9B9BC5;
        font-size: 30px;
        text-align: center;
        padding: 0 0 1rem 0 !important;

        .dailer-progress-info {
          display: flex;
          justify-content: center;
        }

        .microphone, .hold-buttons {
          i {
            cursor: pointer;
            margin: .5rem 1rem 0 0;
          }
        }

      }

      &-button {

        display: flex;
        justify-content: center;
        align-items: center;
        margin: 1rem 0;

        button {
          
          background: #F52323;
          color: #fff;
          padding: 1.4rem;
          border: 0;
          outline: 0;
          border-radius: 50%;
          cursor: pointer;
          box-shadow: 0 2px 1px rgba(0, 0, 0, 0.2);
          transition: all .2s;
          
          i {
            font-size: 20px;
          }
          
          &:hover {
            opacity: .9;
          }

          &:focus,
          &:active {
              transform: translateY(5%);  
          }

        }

      }

  }

</style>
