<template>
    <div class="widget-card-footer flex items-center" :style="styleSet">
        <div class="flex items-center">
          <img src="@/assets/images/header-logo.png" class="w-6" alt="Xlinx Chat Online Logo" />
          <!-- <span class="bold">Xlinx</span>  -->
          <!-- <span class="text-small">&nbsp;Group</span>  -->
          <span style="font-size: 9px" class="ml-1">&copy;2007-2022</span>
        </div>
        <span class="right" :style="{ color: styleSet.color }">
          <a href="widget_configs.links.gdpr" target="_blank">gdpr</a> | 
          <a href="widget_configs.links.terms" target="_blank">{{ translatedValue('term', 'term') }}</a>
        </span> 
    </div>
</template>

<script setup>

import { inject, computed } from 'vue'
import { useStore } from 'vuex'
const translatedValue = inject('translatedValue')
const store = useStore()
const appearance = computed(() => store.getters['widget/appearance'])

const styleSet = computed(() => {
  return {
    background: appearance.value?.footerBackground || '#000',
    borderColor: appearance.value?.footerBackground || '#000',
    color: appearance.value?.footerColor || '#fff'
  }
})

</script>

<style scoped>
  a {
    text-decoration: none;
  }
</style>