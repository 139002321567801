<template>

    <div class="widget-card-subheader">
      
      <ul class="icons icons-left">

        <li 
          @click.prevent="changeScreen('OpenContactList')" 
          :title="$t('label.widget.multilanguage.modal.home')"
        >
          <Icon 
            name="widget-home"
            :color="styleSet.color"
            :style="{background: styleSet.background}" 
          />      
        </li>

      </ul>
      <ul v-if="permissions.length" class="icons icons-right">
          <template 
              v-for="(permission, index) in permissions" 
              :key="index">
              <li 
                @click.prevent="changeScreen(getWidgetScreen(permission))"
                v-if="permissionValue(permission)"
                :title="translate(getTitleOfIcon(permission), getTitleOfIcon(permission))"
              >
                <Icon 
                  v-if="getPermissionIcon(permission)"
                  :name="getPermissionIcon(permission)"
                  :color="styleSet.color"
                  :style="{background: styleSet.background}"
                />
              </li>
          </template>
      </ul>

    </div>
    
</template>

<script setup>

import { useStore } from 'vuex'
import { inject, computed } from 'vue'
import useSettings from '@/modules/widget/composables/widget/useSettings'

const store = useStore()

const { getWidgetScreen, getPermissionIcon, getTitleOfIcon } = useSettings()
const changeScreen = inject('changeScreen')
const translate = inject('translatedValue')

const department = computed(() => store.getters['widget/department'])

const permissions = computed(() => {
    const permission = department.value?.mainSettings?.contactOptions
    return permission != null && permission != undefined 
    ? Object.keys(permission) 
    : []
})

const appearance = computed(() => store.getters['widget/appearance'])

const styleSet = computed(() => {
  return {
    background: appearance.value?.headerIconBackground || '#fff',
    color: appearance.value?.headerIconColor || '#9b9bc5'
  }
})

const permissionValue = (key) => {
    return department.value ? department.value?.mainSettings?.contactOptions[key] : null
}
        
</script>
